import Raven from 'raven-js';
import { getExtraErrorData } from './getExtraErrorData';
import enviro from 'enviro';
// Copied from https://git.hubteam.com/HubSpot/CRM/blob/master/crm_data/static/js/quotes/monitoring/errorUtils.js
// Which was copied from https://git.hubteam.com/HubSpot/growth-onboarding-reliability/blob/master/growth-onboarding-reliability/static/js/utils/raven.js
const SM_FE = 'smFe';

// Network errors to isolate into groups regardless of URL
const ISOLATED_NETWORK_CODES = ['ABORT', 'NETWORKERROR', 'TIMEOUT', '0', '401', '500', '502'];
const FINGERPRINTS = [{
  key: 'TEST_AVAILABILITY_FAILED',
  message: 'Error: Request for https://api.hubspot.com/meetings-public/v1/test-availability failed with status 0'
}, {
  key: 'INVALID_LINK_TYPE',
  message: 'Error: Invalid link type'
}, {
  // Mostly an issue on mobile/old browsers (likely without a modern Promise lib)
  key: 'PROMISE_RESOLVE_NOT_FUNCTION',
  message: 'TypeError: Promise.resolve is not a function'
}, {
  key: 'SECURITY_FAILED_LOCALSTORAGE_READ',
  message: `SecurityError: Failed to read the 'localStorage'`
}, {
  // This and the below errors all appear to be the same class of error - can be consolidated and
  // ignored unless we hit a large threshold
  key: 'LOADING_CHUNK_I18N_FAILED',
  message: `Loading chunk i18n`
}, {
  key: 'LOADING_CHUNK_MEETINGSPUBLIC_LANG_FAILED',
  message: `Loading chunk MeetingsPublic-lang`
}, {
  key: 'LOADING_CHUNK_MOMENT_FAILED',
  message: `Loading chunk moment`
}, {
  // See https://sentry.hubteam.com/sentry/meetingspublic/issues/8750765/activity/ - should all be the same error
  key: 'WEAKMAP_NOT_DEFINED',
  message: `WeakMap is not defined`
}, {
  key: 'WEAKMAP_CANT_FIND',
  message: `Can't find variable: WeakMap`
}, {
  key: 'ILLEGAL_INVOCATION',
  message: `TypeError: Illegal invocation`
}, {
  key: 'PROPERTY_INCLUDES_UNDEFINED',
  message: `TypeError: Cannot read property 'includes' of undefined`
}, {
  key: 'PROPERTY_BOOKINFO_UNDEFINED',
  message: `TypeError: Cannot read property "bookInfo" from undefined`
}];

/**
 * Produces a fingerprint meant to deduplicate errors that occur
 * across multiple public pages.
 *
 * Returns a distinct fingerprint for grouping, replacing default
 * value: ['{{ default }}']
 *
 * Appending values to an array containing '{{ default }}' may
 * have the opposite effect: In other words, making errors
 * more distinct and creating new groups.
 *
 * @param {Error.Message} errorMessage
 */
export const getFingerprint = errorMessage => FINGERPRINTS.reduce((fingerprint, {
  message,
  key
}) =>
// @ts-expect-error unexpected value
errorMessage && errorMessage.includes(message) ? [key] : fingerprint, null);

// taken from https://stackoverflow.com/a/3809435
const uriRegex =
// eslint-disable-next-line no-useless-escape
/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

// removes portalId or quote publicUrlKey from URL
function stripMeetingsSlug(url = '') {
  const meetingsUrlRegex = /meetings\.hubspot\.com\/(.*$)/;
  const meetingsUrlRegex2 = /hubspot\.com\/meetings\/(.*$)/;
  const matches = url.match(meetingsUrlRegex) || url.match(meetingsUrlRegex2);
  if (!matches) {
    return url;
  }
  const slug = `${matches[1]}`;
  return url.replace(slug, '<meeting_slug>');
}
function stripQueryParams(url = '') {
  return url.replace(/\?.*$/, '');
}
export function cleanseUrl(url = '') {
  return stripMeetingsSlug(stripQueryParams(url));
}
function splitIntoLines(str = '') {
  return str.split(/\r?\n/g);
}
function containsUri(str = '') {
  return uriRegex.test(str);
}
function parseUri(str = '') {
  const matches = uriRegex.exec(str);
  return matches && matches[0];
}

// taken from https://stackoverflow.com/a/1981366
function stripRepeatedWhitespace(str = '') {
  return str.replace(/\s\s+/g, ' ');
}

/**
 * We want to avoid having multiple sentries for the same error but we still want to track it.
 * What we are doing here is manually capturing an error with Sentry and adding a fingerprint.
 * Sentry will group all the errors with the same fingerprint regardless of the actual error.
 * The fingerprint for each error network is:
 * url (without query string) + request verb + response status code
 */
export function catchNetworkError(error, shouldRethrow = true) {
  const code = error.errorCode || error.status;
  if (code && error.options && error.options.url) {
    const cleansedUrl = cleanseUrl(error.options.url);
    const method = error.options.method;
    const envPrefix = enviro.isQa() ? 'QA: ' : '';
    const message = `${envPrefix}${method} ${code}: ${cleansedUrl}`;
    Raven.captureMessage(message, {
      fingerprint: ISOLATED_NETWORK_CODES.includes(code.toString()) ? [code] : [code, method, cleansedUrl],
      extra: getExtraErrorData(error),
      // @ts-expect-error unexpected key
      tags: {
        [SM_FE]: true
      }
    });
    if (window.newrelic && window.newrelic.noticeError) {
      const builtError = Object.assign(new Error(), error, {
        message
      });
      window.newrelic.noticeError(builtError, {
        [SM_FE]: true,
        source: 'noticeError'
      });
    }
  }
  if (shouldRethrow) {
    throw error;
  }
}
function parseStacktracedError(message, type = 'Unspecified Error') {
  const [firstLine] = splitIntoLines(message);
  if (containsUri(firstLine)) {
    // @ts-expect-error mismatched api
    return `Stacktraced Network Error: ${cleanseUrl(parseUri(firstLine))}`;
  } else {
    // Strip any meaningless characters or strings from JS exceptions.
    return `${type}: ${stripRepeatedWhitespace(message)}`;
  }
}
export function ravenDataCallback(data, original) {
  // If the raven request already has a fingerprint, it has already been handled by
  // upstream from us by either our own network failure catches or by other FE tools
  // (e.g. usage tracking), and doesn't need to be assigned a fingerprint
  if (!data.fingerprint) {
    // The gist of the logic here is that we continuously try and find network errors through:
    // - `data` having an `exception` where a URL is mentioned in the first line
    // - `data` having a `request` property
    // - `data` having a `message` where a URL is mentioned in the first line
    // Otherwise we strip for excess whitespace and treat as a JS error.

    let fingerprint;
    const definedFingerprint = getFingerprint(data.message);
    if (definedFingerprint) {
      fingerprint = definedFingerprint;
    } else if (data.exception && data.exception.values && data.exception.values.length > 0) {
      const [exception] = data.exception.values;
      fingerprint = [parseStacktracedError(exception.value, exception.type)];
    } else {
      fingerprint = [parseStacktracedError(data.message)];
    }
    // Ignore default grouping behaviour and go solely off our scrubbed error message
    data.fingerprint = fingerprint;
  }
  return original ? original(data) : data;
}